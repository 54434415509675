import React, { Component } from 'react'
import betone from '../../../assets/betone/ADOBETCO.webp';
import hexagonal from '../../../assets/hexagonal/ADOHEXLAD2.webp';
import tabasco from '../../../assets/tabasco/ADOTABNEG.webp';
import app from '../../../configs/firebase-config'


var itm = []
var name = []
var descripcion_corta = []

export class Adoquin extends Component {



  constructor(props) {
    super(props)

    this.state = ({
      items: [],
      name: [],
      descripcion_corta: []
    })
  }

  componentDidMount() {
    app.child('productos').on('value', (snapshot) => {

      let items = []
      snapshot.forEach((child) => {
        items.push({
          nombre: child.val().nombre,
          descripcion_corta: child.val().descripcion_corta,
        })
      })
      itm = items


      name = itm.map(function (obj) {
        var robj
        robj = obj.nombre
        return robj
      })

      descripcion_corta = itm.map(function (obj) {
        var robj
        robj = obj.descripcion_corta
        return robj
      })

      this.setState({ items: items })

    })
  }



  render() {
    return (
      <div>
        <section>
          <div>
            <center><h1 class="productos-h1" >Línea de adoquines</h1></center>
            <div class="principal">
              <div class="card-producto">
                <div class="title">{name[1]}</div>
                <div class="features">
                  <img src={hexagonal} alt="Block" width='' height='' className='card_logo-img'></img>
                  <p>{descripcion_corta[1]}</p>
                </div>
                <a href="/productos/adoquin/hexagonal" class="btn">VER</a>
              </div>
              <div class="card-producto">
                <div class="title" >{name[2]}</div>
                <div class="features">
                  <img src={tabasco} alt="Block" width='' height='' className='card_logo-img'></img>
                  <p>{descripcion_corta[2]}</p>
                </div>
                <a href="/productos/adoquin/tabasco" class="btn">VER</a>
              </div>
              <div class="card-producto">
                <div class="title">{name[0]}</div>
                <div class="features">
                  <img src={betone} alt="Block" width='' height='' className='card_logo-img'></img>
                  <p>{descripcion_corta[0]}</p>
                </div>
                <a href="/productos/adoquin/betone" class="btn">VER</a>
              </div>
            </div>
          </div>
        </section>
      </div>

    )
  }
}