import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

import poste1 from '../../../assets/tuberias/poste.webp'
import poste2 from '../../../assets/tuberias/postenorma.webp'


import carr1 from '../../../assets/src/poste_01.webp'
import carr2 from '../../../assets/src/extra_07.webp'
import carr3 from '../../../assets/src/poste_02.webp'
import descarga from '../../../assets/fichasTecnicas/PostedeConcreto.pdf'

import app from '../../../configs/firebase-config'

var itm = {}
export class Poste2 extends Component {
  constructor(props) {
    super(props)

    this.state = ({
      item: {},
      img: ''
    })
  }

  componentDidMount() {
    app.child('productos/POS15/').on('value', (snapshot) => {
      let items = {}
      items = snapshot.val()
      itm = items
      this.setState({ item: items })
    })
  }


  render() {
    return (
      <>
        <section class="section-marpro" style={{ marginTop: '10%' }}>
          <center><h1 class="h1p">{itm.nombre}</h1></center>
          <div class="container-fluid">
            <div class="cart">
              <div class="row">
              <div style={{width:'50%'}}>
                  <Carousel infiniteLoop autoPlay centerMode>
                    <div >
                      <img src={poste1} alt='Poste de concreto' width='' height='' style={{width:'50%'}}/>
                    </div>
                    <div>
                      <img src={poste2} alt='Poste de concreto' width='' height='' style={{width:'50%'}}/>
                    </div>
                  </Carousel>
                </div>

                <div style={{width:'45%'}}>
                  <div class="product-inf">
                    <div class="tabs-content">
                      <div >
                        <h2>Descripción</h2>
                        <p>{itm.descripcion}.</p>
                      </div>
                      <div >
                        <h2>Detalles</h2>
                        <p>&#x2022; Peso: {itm.peso}.</p>
                        <p>&#x2022; Dimensiones: {itm.dimensiones}.</p>
                        <p>&#x2022; Resistencia: {itm.resistencia}.</p>
                        <p>&#x2022; Rendimiento: {itm.rendimiento}.</p>
                      </div>
                    </div>
                  </div>
                  
                  <div>
                  <a href={descarga} download="Poste de Concreto" class="custom-btn">Descargar Ficha Técnica</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ marginTop: '5%', marginBottom: '5%' }}>
          <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false}>
            <div >
              <img src={carr1} style={{ width: '50%' }} />
            </div>
            <div>
              <img src={carr2} style={{ width: '50%' }} />
            </div>
            <div>
              <img src={carr3} style={{ width: '50%' }} />
            </div>
          </Carousel>
        </section>

      </>

    )
  }
}