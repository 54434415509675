import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import block from '../.././assets/block/IBMAECO.webp';
import adoquin from '../.././assets/hexagonal/ADOHEXCO20.webp';
import poste from '../.././assets/tuberias/poste.webp';
import maceta from '../.././assets/tuberias/maceta2.webp';
import tuberia from '../.././assets/tuberias/tuberia.webp';
import tb from '../.././assets/block/TN.webp';
import profesionista from '../.././assets/recursos/Profesionistas.webp';
import maestro from '../.././assets/recursos/Maestro.webp';
import autoconstruccion from '../.././assets/recursos/Autoconstruccion.webp';

import febrero from '../../assets/recursos/febrero.webp'
import marzo from '../../assets/recursos/marzo.webp'
import abril from '../../assets/recursos/abril.webp'
import experiencia from '../../assets/recursos/Experiencia.webp'
import mejora from '../../assets/recursos/Mejora.webp'
import indutria from '../../assets/recursos/Industria.webp'
import seguridad from '../../assets/recursos/Seguridad.webp'

import app from '../../configs/firebase-config'

var itm = []

var name = []
var descripcion = []
var titulo = []
var id = []
var contenido = []
export class Contenido extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            items: [],
            name: [],
            descripcion: [],
            titulo: [],
            id: [],
            contenido: []
        })
    }

    componentDidMount() {
        app.child('lineas').on('value', (snapshot) => {

            let items = []
            snapshot.forEach((child) => {
                items.push({
                    nombre: child.val().nombre,
                    descripcion: child.val().descripcion,
                })
            })
            itm = items


            name = itm.map(function (obj) {
                var robj
                robj = obj.nombre
                return robj
            })

            descripcion = itm.map(function (obj) {
                var robj
                robj = obj.descripcion
                return robj
            })

            this.setState({ items: items })
        })

        app.child('blog').on('value', (snapshot) => {
            let items = []
            snapshot.forEach((child) => {
                items.push({
                    id: child.val().id,
                    titulo: child.val().titulo,
                    contenido: child.val().tipo,
                })
            })

            itm = items

            titulo = itm.map(function (obj) {
                var robj
                robj = obj.titulo
                return robj
            })
            id = itm.map(function (obj) {
                var robj
                robj = obj.id
                console.log(robj)
                return robj
            })

            contenido = itm.map(function (obj) {
                var robj
                robj = obj.contenido
                return robj
            })
            this.setState({ items: items })
        })
    }

    render() {
        return (
            <div >
                <section className="section-mar">
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false}>
                        <div >
                            <img src={febrero} alt='Portada' className='img-carrusel' width='' height='' />
                        </div>
                        <div>
                            <img src={marzo} alt='Portada' className='img-carrusel' width='' height='' />
                        </div>
                        <div>
                            <img src={abril} alt='Portada' className='img-carrusel' width='' height='' />
                        </div>
                    </Carousel>
                </section>
                <section >
                    <center>
                        <h1 class="h1cont" style={{ marginTop: '5%' }} >NUESTROS PRODUCTOS</h1>
                        <h1 className="h1r">Conoce nuestras líneas de productos prefabricados</h1>
                    </center>
                    <div className="principal">
                        <div className="card">
                            <div className="title">
                                {name[1]}
                            </div>
                            <div className="features">
                                <img src={block} alt="Block" className='card_logo' width='' height='' />
                                <p>{descripcion[1]}</p>
                            </div>
                            <a href="/productos/block" className="btn">VER</a>
                        </div>
                        <div className="card">
                            <div className="title">{name[0]}</div>
                            <div className="features">
                                <img src={adoquin} alt="Adoquin" className='card_logo' width='' height='' />
                                <p>{descripcion[0]}</p>
                            </div>
                            <a href="/productos/adoquin" className="btn">VER</a>
                        </div>
                        <div className="card">
                            <div className="title">{name[4]}</div>
                            <div className="features">
                                <img src={tb} alt="Tabicon" className='card_logo' style={{ marginTop: '15%' }} width='' height='' />
                                <p>{descripcion[4]}</p>
                            </div>
                            <a href="/productos/tabicon/" className="btn">VER</a>
                        </div>
                    </div>
                    <div className="principal">
                        <div className="card">
                            <div className="title">{name[5]}</div>
                            <div className="features">
                                <img src={tuberia} alt="Tuberia" className='card_logo' style={{ marginTop: '15%' }} width='' height='' />
                                <p>{descripcion[5]}</p>
                            </div>
                            <a href="/productos/tuberias_de_concreto" className="btn">VER</a>
                        </div>
                        <div className="card">
                            <div className="title">{name[3]}</div>
                            <div className="icon">
                            </div>
                            <div className="features">
                                <img src={poste} alt="Poste" className='card_logo' style={{ height: '20vw', width: '10vw', marginTop: '7%' }} width='' height='' />
                                <p>{descripcion[3]}</p>
                            </div>
                            <a href="/productos/postes_de_concreto" className="btn">VER</a>
                        </div>
                        <div className="card">
                            <div className="title">{name[2]}</div>
                            <div className="icon">
                            </div>
                            <div className="features">
                                <img src={maceta} alt="Decorativo" className='card_logo' width='' height='' />
                                <p>{descripcion[2]}</p>
                            </div>
                            <a href="/productos/decorativos" className="btn">VER</a>
                        </div>
                    </div>
                </section>
                <section>
                    <center><br /><br /><br /><br />
                        <h1 className="h1r">Conoce las ventajas de trabajar con nosotros</h1>
                    </center>
                    <div>
                        <div className="principal-r">
                            <div className="div-dis">
                                <p>Experiencia</p>
                                <img src={experiencia} alt="Experiencia" class="img-div-dis" width='' height='' />
                                <p>Somos una empresa bien establecida que cuenta con 30 años de experiencia en la región</p>
                            </div>
                            <div className="div-dis">
                                <p>Seguridad</p>
                                <img src={seguridad} alt="Seguridad" class="img-div-dis" width='' height='' />
                                <p>La resistencia de nuestros productos cumplen con las Normas Mexicanas de la Construcción</p>
                            </div>
                            <div className="div-dis">
                                <p>Mejora Continua</p>
                                <img src={mejora} alt="Mejora" class="img-div-dis" width='' height='' />
                                <p>Estamos en constante proceso de mejora para brindarte un mejor servicio y los mejores productos</p>
                            </div>
                            <div className="div-dis">
                                <p>Fabricación Industrializada</p>
                                <img src={indutria} alt="Industrializada" class="img-div-dis" width='' height='' />
                                <p>Nuestro proceso de producción es industrializada asegurandote material de la mejor calidad </p>
                            </div>
                        </div>

                    </div>
                </section>
                <section>
                    <div className="div-of">
                        <center><br /><br /><h1 className='hd'>Ofrecemos soluciones para</h1>
                        </center>
                        <div className="principal">
                            <div className="div-dis-of">
                                <p className="p-cont">PROFESIONISTAS</p>
                                <img src={profesionista} alt="Profesionista" className="img-of" width='' height='' />
                            </div>
                            <div className="div-dis-of">
                                <p className="p-cont">MAESTROS DE OBRA</p>
                                <img src={maestro} alt="Maestro de obra" className="img-of" width='' height='' />
                            </div>
                            <div className="div-dis-of">
                                <p className="p-cont">AUTOCONSTRUCCIÓN</p>
                                <img src={autoconstruccion} alt="Autoconstrucción" className="img-of" width='' height='' />
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section>
                    <div style={{ marginTop: '5%', marginBottom:'5%'}}>
                        <center><h1>Noticias</h1></center>
                        <div className="principal">
                            <div className="div-dis-blog">
                                <p className="p-cont">{contenido[2]}</p>
                                <p className="p-cont">{titulo[2]}</p>
                                <img src={autoconstruccion} alt="Noticia" className="img-blog-inicio" width='' height='' />
                                <a href={`/noticia/${id[2]}`} class="btn">Leer más</a>
                            </div>
                            <div className="div-dis-blog">
                                <p className="p-cont">{contenido[0]}</p>
                                <p className="p-cont">{titulo[0]}</p>
                                <img src={autoconstruccion} alt="Noticia" className="img-blog-inicio" width='' height='' />
                                <a class="btn">Leer más</a>
                            </div>
                            <div className="div-dis-blog">
                                <p className="p-cont">{contenido[1]}</p>
                                <p className="p-cont">{titulo[1]}</p>
                                <img src={autoconstruccion} alt="Noticia" className="img-blog-inicio" width='' height='' />
                                <a class="btn">Leer más</a>
                            </div>
                        </div>
                    </div>
        </section>*/}
            </div>
        )
    }
}
