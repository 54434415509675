import React, { Component } from 'react'

import tuberia from '../../../assets/tuberias/tuberia.webp'
import brocal from '../../../assets/tuberias/brocal.webp'
import app from '../../../configs/firebase-config'


var itm = []
var name = []
var descripcion_corta = []
export class Tuberia extends Component {
  constructor(props) {
    super(props)

    this.state = ({
      items: [],
      name: [],
      descripcion_corta: []
    })
  }

  componentDidMount() {
    app.child('productos').on('value', (snapshot) => {

      let items = []
      snapshot.forEach((child) => {
        items.push({
          nombre: child.val().nombre,
          descripcion_corta: child.val().descripcion_corta,
        })
      })
      itm = items


      name = itm.map(function (obj) {
        var robj
        robj = obj.nombre
        return robj
      })

      descripcion_corta = itm.map(function (obj) {
        var robj
        robj = obj.descripcion_corta
        return robj
      })

      this.setState({ items: items })

    })
  }


  render() {
    return (
      <div>
        <section>
          <div>
            <center><h1 class="productos-h1" >Tuberias de concreto y juego de brocales</h1></center>
            <div class="principal">
              <div class="card-producto">
                <div class="title">{name[12]}</div>
                <div class="features">
                  <img src={tuberia} alt="Tuberia de concreto" width='' height='' className='card_logo-img' style={{ height: '65%' }}></img>
                  <p>{descripcion_corta[12]}.</p>
                </div>
                <a href="/productos/tuberia/" class="btn">VER</a>
              </div>
              <div class="card-producto">
                <div class="title" style={{ fontSize: '20px' }}>{name[7]}s</div>
                <div class="features">
                  <img src={brocal} alt="Juego de brocales" width='' height='' className='card_logo-img' style={{ width: '83%' }}></img>
                  <p>{descripcion_corta[7]}.</p>
                </div>
                <a href="/productos/juego-brocales/" class="btn">VER</a>
              </div>

            </div>
          </div>
        </section>
      </div>
    )
  }
}