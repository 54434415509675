import React, { Component, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import app from '../../configs/firebase-config';

import Img from '../../assets/blocks.jpg';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const editorConfiguration = {
  toolbar: {
    items: [],
  },
  image: {
    toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },
};

export const Noticia = () => {
  const { id } = useParams();

  const itemValues = {
    titulo: '',
    tipo: '',
    fecha: '',
    contenido: '',
  };

  const [noticia, setNoticia] = useState(itemValues);

  useEffect(() => {
    app.child('blog').on('value', (snapshot) => {
      snapshot.forEach((child) => {
        if (child.key === id) {
          setNoticia(child.val());
        }
      });
    });
  }, []);

  const modules = {
    toolbar: [],
  };

  const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'align',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  return (
    <>
      <div>
        <div className='margen-blog' style={{ marginTop: '1%' }}>
          <img
            src={Img}
            alt='Noticia'
            width=''
            height=''
            className='imgn-blog'
          />
          <h1 className='centrado'>{noticia.titulo}</h1>
        </div>
      </div>
      <div>
        <div className='contenido-blog' style={{ marginBottom: '30px' }}>
          <p style={{ display: 'flex', justifyContent: 'left' }}>
            <i className='fecha-blog'>{noticia.fecha}</i>
          </p>
          <div>
            <ReactQuill
              theme='bubble'
              readOnly={true}
              value={noticia.contenido}
              modules={modules}
              formats={formats}
              disabled
            />
          </div>
          <img src={Img} className='blogimg' />
        </div>
      </div>
    </>
  );
};
