import React, { Component, useState } from 'react'
import Swal from 'sweetalert2'

import ContactoForm from './form'

import mapa from '../../assets/mapa.gif'
import tel from '../../assets/telefono.gif'
import wpp from '../../assets/whatsapp.webp'
import email from '../../assets/mensaje.gif'
import bodega from '../../assets/recursos/Bodega.webp'

import app from '../../configs/firebase-config'

export const Contacto = () => {

  const addMessage = (obj) => {
    app.child('mensajes').push(obj, (err) => {
      if (err) Swal.fire('Error', err, 'error', 1500)
      else Swal.fire('Hecho', 'Se ha enviado tu mensaje exitosamente', 'success', 1500)
    })
  }

  return (
    <>
      <div  >
        <section >
          <center>
            <h1 class="h1-contact" >CONTÁCTANOS</h1>
            <h4 class="h4-contact">Te ayudamos a dar solución a cualquier duda, problema o inquietud sobre nuestros productos.</h4>
          </center>
        </section>
        <section>
          <div class="div-principal">
            <div class="div-dis-img">
              <p>Vísitanos en</p>
              <img src={mapa} alt="mapa" class="img-cont" width='' height='' />
              <p>Carretera Cd. Hidalgo - Maravatío #1300 <br />Col. Rancho Solache</p>
            </div>
            <div class="div-dis-img">
              <p>Llámanos</p>
              <img src={tel} alt="tel" class="img-cont" width='' height='' />
              <p>786 168 0164 <br />ó <br />786 154 1433</p>
            </div>
            <div class="div-dis-img">
              <p>Envíanos un mensaje</p>
              <img src={wpp} alt="wpp" class="img-cont" width='' height='' />
              <p>786 112 9500</p>
            </div>
            <div class="div-dis-img">
              <p>O comunicate por e-mail</p>
              <img src={email} alt="email" class="img-cont" width='' height='' />
              <br /><span>mat_preconsa @
                outlook.com</span>
            </div>
          </div>
        </section>
        <section class="section-size">
          <center>
            <h1 class="h1-contact-form">Dejanos un mensaje</h1>
          </center>
          <ContactoForm {...{ addMessage }} />
        </section>
        <section style={{ backgroundColor: '#F6B352', marginBottom: '30px' }}>

          <img src={bodega} alt="Bodega" style={{ width: '50%', marginLeft: '25%', marginRight: '25%', marginBottom: '30px', marginTop: '30px' }} width='' height='' />
        </section>
      </div>
    </>
  )

}