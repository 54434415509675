import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

import rosa from '../../../assets/block/IBMRECO.webp'
import cafe from '../../../assets/block/IBMCAFO.webp'
import negro from '../../../assets/block/IBMNEG.webp'
import amarillo from '../../../assets/block/IBMAECO.webp'
import natural from '../../../assets/block/IBMNECO.webp'
import ladrillo from '../../../assets/block/IBMLAD.webp'

import carr1 from '../../../assets/src/block_02.webp'
import carr2 from '../../../assets/src/block_05.webp'
import carr3 from '../../../assets/src/block_07.webp'
import descarga from '../../../assets/fichasTecnicas/BlockMacizoLiso.pdf'

import app from '../../../configs/firebase-config'


var itm = {}
export class Macizo extends Component {

  constructor(props) {
    super(props)

    this.state = ({
      item: {},
      img: ''
    })
  }

  componentDidMount() {
    app.child('productos/IBMNECO/').on('value', (snapshot) => {
      let items = {}
      items = snapshot.val()
      itm = items
      this.setState({ item: items })
    })
  }


  render() {
    return (
      <>
        <section className="section-marpro" >
          <center><h1 className="h1p">{itm.nombre}</h1></center>
          <div className="container-fluid">
            <div className="cart">
              <div className="row">
                <div style={{ width: '50%' }}>
                  <Carousel infiniteLoop autoPlay centerMode showStatus={false}>
                    <div >
                      <img src={natural}  style={{ width: '70%', height:'auto' }} width='' height='' alt='Block Liso Natural' />
                    </div>
                    <div>
                      <img src={rosa} style={{ width: '70%', height:'auto' }} width='' height='' alt='Block Liso Rosa' />
                    </div>
                    <div>
                      <img src={negro} style={{ width: '70%', height:'auto' }} width='' height='' alt='Block Liso Negro' />
                    </div>
                    <div>
                      <img src={cafe} style={{ width: '70%', height:'auto' }} width='' height='' alt='Block Liso Cafe' />
                    </div>
                    <div>
                      <img src={amarillo} style={{ width: '70%', height:'auto' }} width='' height='' alt='Block Liso Amarillo' />
                    </div>
                    <div>
                      <img src={ladrillo} style={{ width: '70%', height:'auto' }} width='' height='' alt='Block Liso Ladrillo' />
                    </div>
                  </Carousel>
                </div>
                <div >
                  <div className="product-inf">
                    <div className="tabs-content">
                      <div >
                        <h2>Descripción</h2>
                        <p>{itm.descripcion}.</p>
                      </div>
                      <div >
                        <h2>Detalles</h2>
                        <p>&#x2022; Peso: {itm.peso}.</p>
                        <p>&#x2022; Dimensiones: {itm.dimensiones}.</p>
                        <p>&#x2022; Resistencia: {itm.resistencia}.</p>
                        <p>&#x2022; Rendimiento: {itm.rendimiento}.</p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '5%' }}>
                    <center><h3>Colores disponibles</h3></center>
                    <div className="colors">
                      <ul>
                        <li className="li-prod natural"></li>
                        <li className="li-prod pinky" ></li>
                        <li className="li-prod black"></li>
                        <li className="li-prod brown"></li>
                        <li className="li-prod yellow"></li>
                        <li className="li-prod ladrillo"></li>
                      </ul>
                    </div>
                  </div>
                  <div>
                  <a href={descarga} download="Block Macizo Liso" class="custom-btn">Descargar Ficha Técnica</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ marginTop: '5%', marginBottom: '5%' }}>
          <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false}>
            <div >
              <img src={carr1} style={{ width: '50%' }} alt='' />
            </div>
            <div>
              <img src={carr2} style={{ width: '50%' }} alt='' />
            </div>
            <div>
              <img src={carr3} style={{ width: '50%' }} alt='' />
            </div>
          </Carousel>
        </section>
      </>

    )
  }
}