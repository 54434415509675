import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, Form, ErrorMessage, Field } from 'formik'

const ContactoForm = (props) => {

    const initialValues = {
        nombre: '',
        correo: '',
        telefono: '',
        mensaje: '',
    }

    var [values, setValues] = useState(initialValues)

    const formSchema = Yup.object().shape({
        nombre: Yup.string()
            .required('Campo Requerido'),
        telefono: Yup.string()
            .required('Campo Requerido'),
        mensaje: Yup.string()
            .required('Campo Requerido')
            .max(500, 'Máximo 500 caracteres')
    })

    const handleInputChange = (e) => {
        var { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    nombre: '',
                    correo: '',
                    telefono: '',
                    mensaje: ''
                }}
                validationSchema={formSchema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    props.addMessage(values)
                    resetForm({ values: '' })
                }}
            >
                <Form className='form'>
                    <div className='large-group'>
                        <div className='small-group'>
                            <label htmlFor="nombre" className="label">Nombre Completo</label>
                            <Field 
                                id="nombre" 
                                type="text" 
                                name="nombre" 
                                placeholder='Escribe tu Nombre'
                                onKeyUp={handleInputChange}
                            />
                            <ErrorMessage name='nombre' component='div' />
                        </div>
                        <div className='small-group'>
                            <label htmlFor="correo" className="label">Email</label>
                            <Field 
                                id="correo" 
                                type="email" 
                                name="correo" 
                                placeholder='Escribe tu Correo Electrónico'
                                onKeyUp={handleInputChange}
                            />
                            <ErrorMessage name='correo' component='div' />
                        </div>
                        <div className='small-group'>
                            <label htmlFor="telefono" className="label">Número telefonico</label>
                            <Field 
                                id="telefono" 
                                type="tel" 
                                name="telefono" 
                                placeholder='Ingresa tu Número de Teléfono'
                                onKeyUp={handleInputChange}
                            />
                            <ErrorMessage name='telefono' component='div' />
                        </div>
                        <div className='textarea-div'>
                            <label htmlFor="mensaje" className="label">Mensaje</label>
                            <Field 
                                id="mensaje" 
                                type="text" 
                                name="mensaje" 
                                placeholder='Escribe tu Mensaje'
                                onKeyUp={handleInputChange}
                                as='textarea'
                            />
                        </div>
                        <input id="submit" className="btnform" type="submit" name="submit" />
                    </div>
                </Form>
            </Formik>
            <form className="formF">
                    <div className="div-map">
                        <div className="mapouter">
                            <div className="gmap_canvas" >
                                <iframe className="frame" id="gmap_canvas" src="https://maps.google.com/maps?q=19.6859598,%20-100.5193629&t=&z=16&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                                <a href="https://putlocker-is.org"></a>
                                <a href="https://www.embedgooglemap.net"></a>
                            </div>
                        </div>
                    </div>
                    <div className="place-btn"><a href='https://www.google.com/maps/dir//19.68553,-100.52055/@19.68553,-100.52055,19z' className="a_cont" target={"_blank"}>¿Cómo llegar?</a></div>
                </form>
        </>
    )
}

export default ContactoForm
