import React, { Component } from 'react'
import { Adoquin } from "../../components/Productos/Adoquin"
import { Block } from "../../components/Productos/Block"
import { Tuberia } from "../../components/Productos/Tuberia"
import { Poste } from "../../components/Productos/Poste"
import { Decorativos } from "../../components/Productos/Decorativos"
import { Tabicon } from "../../components/Productos/Tabicon"
export class Productos extends Component {
  render() {
    return (
      <div class="productos-div">
        <Block/>
        <Adoquin/>
        <Tabicon/>
        <Poste/>
        <Tuberia/>
        <Decorativos/>
      </div>
    )
  }
}