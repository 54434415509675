import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";


import brocal from '../../../assets/tuberias/brocal.webp'

import carr1 from '../../../assets/src/brocal_01.webp'
import carr2 from '../../../assets/src/extra_09.webp'
import carr3 from '../../../assets/src/brocal_01.webp'

import app from '../../../configs/firebase-config'

var itm = {}
export class Brocales extends Component {
  
  constructor(props) {
    super(props)

    this.state = ({
      item: {},
      img: ''
    })
  }

  componentDidMount() {
    app.child('productos/BRO1X60/').on('value', (snapshot) => {
      let items = {}
      items = snapshot.val()
      itm = items
      this.setState({ item: items })
    })
  }
  render() {
    return (
      <>
      

        <section class="section-marpro" >
          <center><h1 class="h1p">{itm.nombre}</h1></center>
          <div class="container-fluid">
            <div class="cart">
              <div class="row">
              <div style={{width:'50%'}}>
                  <img src={brocal} class="img-style"  alt='Juego de brocal' width='' height=''style={{width:'60%', marginLeft:'20%'}} />
                </div>

                <div style={{width:'45%'}} >
                  <div class="product-inf">
                    <div class="tabs-content">
                      <div >
                        <h2>Descripción</h2>
                        <p>{itm.descripcion}.</p>
                      </div>
                      <div >
                        <h2>Detalles</h2>
                        <p>&#x2022; Peso: {itm.peso}.</p>
                        <p>&#x2022; Piezas: {itm.piezas}.</p>
                        <p>&#x2022; Dimensiones: {itm.dimensiones}.</p>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{marginTop:'5%', marginBottom:'5%'}}>
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false}> 
                        <div >
                            <img src={carr1} style={{width:'50%'}}/>
                        </div>
                        <div>
                            <img src={carr2} style={{width:'50%'}} />
                        </div>
                        <div>
                            <img src={carr3}  style={{width:'50%'}}/>
                        </div>
                    </Carousel>
          </section>

      </>

    )
  }
}