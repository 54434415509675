import React, { Component } from 'react'

import Macizo from '../../../assets/block/IBMNEG.webp';
import CP from '../../../assets/cp/BMACP.webp';

import Ranurado from '../../../assets/block/BMNRAN39-1.webp';
import Hueco from '../../../assets/block/BHN.webp';
import app from '../../../configs/firebase-config'


var itm = []
var name = []
var descripcion_corta = []

export class Block extends Component {


  constructor(props) {
    super(props)

    this.state = ({
      items: [],
      name: [],
      descripcion_corta: []
    })
  }

  componentDidMount() {
    app.child('productos').on('value', (snapshot) => {

      let items = []
      snapshot.forEach((child) => {
        items.push({
          nombre: child.val().nombre,
          descripcion_corta: child.val().descripcion_corta,
        })
      })
      itm = items


      name = itm.map(function (obj) {
        var robj
        robj = obj.nombre
        return robj
      })

      descripcion_corta = itm.map(function (obj) {
        var robj
        robj = obj.descripcion_corta
        return robj
      })

      this.setState({ items: items })

    })
  }



  render() {
    return (
      <div>
        <section>
          <div>
            <center><h1 class="productos-h1">Línea de block</h1></center>
            <div class="principal">
              <div class="card-producto">
                <div class="title">{name[8]}</div>
                <div class="features">
                  <img src={Macizo} alt="Block macizo" width='' height='' className='card_logo-img'></img>
                  <p>{descripcion_corta[8]}</p>
                </div>
                <a href="/productos/block/macizo-liso" class="btn">VER</a>
              </div>
              <div class="card-producto">
                <div class="title" style={{ fontSize: '20px' }}>{name[6]}</div>
                <div class="features">
                  <img src={Ranurado} alt="Block ranurado" width='' height='' className='card_logo-img'></img>
                  <p>{descripcion_corta[6]}</p>
                </div>
                <a href="/productos/block/ranurado" class="btn">VER</a>
              </div>
              <div class="card-producto">
                <div class="title" >{name[5]}</div>
                <div class="features">
                  <img src={CP} alt="Block Cara de Piedra" width='' height='' className='card_logo-img'></img>
                  <p>{descripcion_corta[5]}</p>
                </div>
                <a href="/productos/block/cara-de-piedra" class="btn">VER</a>
              </div>
              <div class="card-producto">
                <div class="title">{name[4]}</div>
                <div class="features">
                  <img src={Hueco} alt="Block Hueco" width='' height='' className='card_logo-img' style={{ marginTop: '0%' }}></img>
                  <p>{descripcion_corta[4]}</p>
                </div>
                <a href="/productos/block/hueco" class="btn">VER</a>
              </div>
            </div>
          </div>
        </section>
      </div>

    )
  }
}