import React, { Component } from 'react'
import carr1 from '../../assets/cr1.jpeg'
import carr2 from '../../assets/cr2.jpeg'
import carr3 from '../../assets/cr3.jpeg'
import i1 from '../../assets/cp/BMACP.webp'
import i2 from '../../assets/cp/BMLADCP.webp'
import i3 from '../../assets/cp/BMNCP.webp'
import i4 from '../../assets/cp/BMACP.webp'
import descarga from '../../assets/cr3.jpeg'

import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
export class Producto extends Component {
  render() {
    return (
      <>
        <section class="body_sec" >
          <br /><br /><br />
          <figure class="icon-cards mt-3">
            <div class="icon-cards__content">
              <div class="icon-cards__item d-flex align-items-center justify-content-center"><img src={carr1} class="img-carrusel" /></div>
              <div class="icon-cards__item d-flex align-items-center justify-content-center"><img src={carr2} class="img-carrusel" /></div>
              <div class="icon-cards__item d-flex align-items-center justify-content-center"><img src={carr3} class="img-carrusel" /></div>
            </div>
          </figure>
        </section>

        <section class="section-marpro">
          <center><h1 class="h1p">NOMBRE DEL PRODUCTO</h1></center>
          <div class="container-fluid">
            <div class="cart">
              <div class="row">
                <div style={{width:'60%'}}>
                  <Carousel infiniteLoop autoPlay centerMode>
                    <div >
                      <img src={i1} style={{width:'70%'}}/>
                    </div>
                    <div>
                      <img src={i2}  style={{width:'70%'}}/>
                    </div>
                    <div>
                      <img src={i3} style={{width:'70%'}}/>
                    </div>
                    <div>
                      <img src={i4}  style={{width:'70%'}}/>
                    </div>
                    <div>
                      <img src={i2}  style={{width:'70%'}}/>
                    </div>
                    <div>
                      <img src={i3} style={{width:'70%'}}/>
                    </div>
                  </Carousel>
                </div>
                <div >
                  <div class="product-inf">
                    <div class="tabs-content">
                      <div >
                        <h2>Descripción</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, inventore magnam! Natus, quibusdam ea? Modi nemo corrupti alias quae quis, amet consectetur adipisicing elit. Vero, inventore magnam! Natus, quibusdam ea? Modi nemo corrupti alias quae quis.</p>
                      </div>
                      <div >
                        <h2>Detalles</h2>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Atque sequi eaque quisquam corrupti reprehenderit exercitationem iusto asperiores quae commodi quo? amet consectetur adipisicing elit. Vero, inventore magnam! Natus, quibusdam ea? Modi nemo corrupti alias quae quis.</p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '5%' }}>
                    <center><h3>Colores disponibles</h3></center>
                    <div class="colors">
                      <ul>
                        <li class="li-prod pinky" ></li>
                        <li class="li-prod black"></li>
                        <li class="li-prod brown"></li>
                        <li class="li-prod natural"></li>
                        <li class="li-prod yellow"></li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <a href='' download={descarga} class="custom-btn">Descargar Ficha Técnica</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


      </>

    )
  }
}