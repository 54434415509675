import logoFooter from '../../../assets/logo2.webp'

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <footer className='footer-distributed'>
        <div className='footer-left'>
          <a href="/#"><img
            src={logoFooter}
            width='' height=''
            className='footer-distributed_logo'
            alt='Logo Materiales Preconsa'
          /></a>
          <p className='footer-links'>
            <a className='link-1' href='/#'>
              Inicio
            </a>
            <a href='/us'>Nosotros</a>
            <a href='/productos'>Productos </a>
            <a href='/working'>Noticias</a>
            <a href='/contacto'>Contacto</a>
          </p>
        </div>
        <div className='footer-center'>
          <div className='footer-center-container'>
            <div className='footer-center-circle'>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </div>
            <p><a target='_blank' href="https://goo.gl/maps/uP1vtcuXZghSMmu99">
              <span>Carretera Cd. Hidalgo-Maravatío #1300</span>
              <span>Col.Rancho Solache</span>
                Ciudad Hidalgo, Michoacán
              </a>
            </p>
          </div>
          <div className='footer-center-container'>
            <div className='footer-center-circle'>
              <FontAwesomeIcon icon={faPhone} />
            </div>
           <p>(786) 168 0164</p>
            
          </div>
          <div className='footer-center-container'>
            <div className='footer-center-circle'>
            <FontAwesomeIcon icon={faWhatsapp} />
            </div>
            <p>(786) 112 9500</p>
          </div>
        </div>
        <div className='footer-right'>
          <p className='footer-company-about'>
            <span>¿Quiénes Somos?</span>
            Somos una empresa dedicada a la producción de prefabricados
             de concreto que con un trabajo honesto y responsable brindamos
              confianza y seguridad a nuestros clientes.
          </p>

          <div className='footer-icons'>
            <a target='_blank' href='https://www.facebook.com/matPreconsa/'>
              <i>
                <FontAwesomeIcon icon={faFacebook} />
              </i>
            </a>
            <a target='_blank' href='https://www.instagram.com/mat.preconsa/'>
              <i>
                <FontAwesomeIcon icon={faInstagram} />
              </i>
            </a>
          </div>
        </div>
      </footer>
    )
  }
}
