import React, {Component} from "react";
import { Contenido } from '../../components/Contenido/index'

export class Index extends Component {
    render() {
        return (
            <div>
                <Contenido />
            </div>
        )
    }
}
