import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Index } from "../views/principal_view"
import { Noticias } from "../components/Blog/noticias.js"
import { Noticia } from "../components/Blog/noticia.js"
import { Blog } from '../components/Blog/index.js'
import { Nosotros } from "../components/Nosotros"
import { Producto } from "../components/Producto"
import { Productos } from "../components/Productos"
import { Contacto } from "../components/Contacto"
import { Navbar } from "../components/UI/Navbar"
import { Footer } from "../components/UI/Footer"
import { Working } from "../components/Working"
import { Adoquin } from "../components/Productos/Adoquin"
import { Block } from "../components/Productos/Block"
import { Tuberia } from "../components/Productos/Tuberia"
import { Poste } from "../components/Productos/Poste"
import { Decorativos } from "../components/Productos/Decorativos"

import { Betone } from "../components/Producto/P_Adoquin/betone.js"
import { Hexagonal } from "../components/Producto/P_Adoquin/hexagonal.js"
import { Tabasco } from "../components/Producto/P_Adoquin/tabasco.js"

import { Macizo } from "../components/Producto/P_Block/macizo.js"
import { Ranurado } from "../components/Producto/P_Block/ranurado.js"
import { Hueco } from "../components/Producto/P_Block/hueco.js"
import { Cp } from "../components/Producto/P_Block/cara_piedra.js"

import { Tuberias } from "../components/Producto/P_Tuberia/tuberias.js"
import { Brocales } from "../components/Producto/P_Tuberia/brocales.js"

import { Poste1 } from "../components/Producto/P_Poste/poste1.js"
import { Poste2 } from "../components/Producto/P_Poste/poste2.js"

import { Maceta } from "../components/Producto/P_Decorativo/maceta.js"
import { Banca } from "../components/Producto/P_Decorativo/banca.js"

import { P_Tabicon } from "../components/Producto/P_Tabicon"
export const AppRouter = () => {

    return (
        <>
            <Navbar />
            <Router>
                <Routes>
                    <Route path='/inicio' element={<Index />} />
                    <Route path='/*' element={<Index />} />
                    <Route path='/noticias' element={<Noticias />} />
                    <Route path='/noticia/:id' element={<Noticia />} />
                    <Route path='/us' element={<Nosotros />} />
                    <Route path='/contacto' element={<Contacto />} />
                    <Route path='/productos' element={<Productos />} />
                    <Route path='/producto' element={<Producto />} />
                    <Route path='/working' element={<Working />} />

                    <Route path='/productos/adoquin' element={<Adoquin />} />
                    <Route path='/productos/block' element={<Block />} />
                    <Route path='/productos/tuberias_de_concreto' element={<Tuberia />} />
                    <Route path='/productos/postes_de_concreto' element={<Poste />} />
                    <Route path='/productos/decorativos' element={<Decorativos />} />

                    <Route path='/productos/adoquin/betone' element={<Betone />} />
                    <Route path='/productos/adoquin/hexagonal' element={<Hexagonal />} />
                    <Route path='/productos/adoquin/tabasco' element={<Tabasco />} />

                    <Route path='/productos/block/macizo-liso' element={<Macizo />} />
                    <Route path='/productos/block/ranurado' element={<Ranurado />} />
                    <Route path='/productos/block/hueco' element={<Hueco />} />
                    <Route path='/productos/block/cara-de-piedra' element={<Cp />} />

                    <Route path='/productos/tuberia/' element={<Tuberias />} />
                    <Route path='/productos/juego-brocales/' element={<Brocales />} />

                    <Route path='/productos/poste/10x10' element={<Poste1 />} />
                    <Route path='/productos/poste/15x15' element={<Poste2 />} />

                    <Route path='/productos/tabicon/' element={<P_Tabicon />} />

                    <Route path='/productos/decorativo/maceta' element={<Maceta />} />
                    <Route path='/productos/decorativo/banca' element={<Banca />} />
                </Routes>
            </Router>
            <Footer />
        </>
    )
}
