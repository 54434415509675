// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTVGPY77I1bOwGFfUxGYzmTqjqdRGUx1w",
  authDomain: "sa-preconsa.firebaseapp.com",
  databaseURL: "https://sa-preconsa-default-rtdb.firebaseio.com",
  projectId: "sa-preconsa",
  storageBucket: "sa-preconsa.appspot.com",
  messagingSenderId: "232908838595",
  appId: "1:232908838595:web:559a0e8ce283f696ed3476",
  measurementId: "G-HL945HMT9D"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export default app.database().ref()