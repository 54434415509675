import React, { Component } from 'react'

import poste1 from '../../../assets/tuberias/poste3.webp';
import poste2 from '../../../assets/tuberias/poste.webp';
import app from '../../../configs/firebase-config'


var itm = []
var name = []
var descripcion_corta = []

export class Poste extends Component {

  constructor(props) {
    super(props)

    this.state = ({
      items: [],
      name: [],
      descripcion_corta: []
    })
  }

  componentDidMount() {
    app.child('productos').on('value', (snapshot) => {

      let items = []
      snapshot.forEach((child) => {
        items.push({
          nombre: child.val().nombre,
          descripcion_corta: child.val().descripcion_corta,
        })
      })
      itm = items


      name = itm.map(function (obj) {
        var robj
        robj = obj.nombre
        return robj
      })

      descripcion_corta = itm.map(function (obj) {
        var robj
        robj = obj.descripcion_corta
        return robj
      })

      this.setState({ items: items })

    })
  }

  render() {
    return (
      <div>
        <section>
          <div>
            <center><h1 class="productos-h1">Postes de concreto</h1></center>
            <div class="principal">
              <div class="card-producto">
                <div class="title">{name[10]}</div>
                <div class="features">
                  <img src={poste1} alt="Postee de concreto" width='' height='' className='card_logo-img' style={{ height: '18vw', width: '7vw' }}></img>
                  <p>{descripcion_corta[10]}</p>
                </div>
                <a href="/productos/poste/10x10" class="btn">VER</a>
              </div>
              <div class="card-producto">
                <div class="title">{name[11]}</div>
                <div class="features">
                  <img src={poste2} alt="Poste de concreto" width='' height='' className='card_logo-img' style={{ height: '18vw', width: '7vw' }}></img>
                  <p>{descripcion_corta[11]}.</p>
                </div>
                <a href="/productos/poste/15x15" class="btn">VER</a>
              </div>

            </div>
          </div>
        </section>
      </div>
    )
  }
}