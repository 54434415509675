import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import carr1 from '../../assets/src/extra_00.webp'
import carr2 from '../../assets/src/extra_01.webp'
import carr3 from '../../assets/src/extra_02.webp'
import carr4 from '../../assets/src/extra_03.webp'
import carr5 from '../../assets/src/extra_07.webp'
import carr6 from '../../assets/src/extra_08.webp'

import equipo from '../../assets/recursos/Equipo.webp'
import eco from '../../assets/recursos/Ecologia.webp'
import honestidad from '../../assets/recursos/Honestidad.webp'
import respeto from '../../assets/recursos/Respeto.webp'

import app from '../../configs/firebase-config'

var itm = []
var value = []
export class Nosotros extends Component {

  constructor(props) {
    super(props)

    this.state = ({
      item: [],
      value: []
    })
  }

  componentDidMount() {
    app.child('filosofia').on('value', (snapshot) => {

      let items = []
      snapshot.forEach((child) => {
        items.push({
          valor: child.val().valor
        })
      })
      itm = items
      value = itm.map(function (obj) {
        var robj
        robj = obj.valor
        return robj
      })
      this.setState({ items: items })
    })

  }

  render() {

    return (
      <div >
        <div className='about-title'>Nuestra Filosofia</div>
        <div class='timeline'>
          <div class='containerA'>
            <i class='iconA'></i>
            <div class='content'>
              <h2>Nuestra Visión</h2>
              <p style={{ textAlign: 'justify' }}>
                {value[0]}
              </p>
            </div>
          </div>
          <div class='containerA'>
            <i class='iconA'></i>
            <div class='content'>
              <h2>Nuestra Misión</h2>
              <p>
                {value[5]}
              </p>
            </div>
          </div>
        </div>

        <div style={{ marginTop: '5%' }}>
          <center><h1>Nuestros valores</h1></center>
          <div className="principal-r">
            <div className="div-valores">
              <p>{value[1]}</p>
              <img src={respeto} alt="Valores" class="img-div-dis" width='' height='' />
            </div>
            <div className="div-valores">
              <p>{value[2]}</p>
              <img src={eco} alt="Valores" class="img-div-dis" width='' height='' />
            </div>
            <div className="div-valores">
              <p>{value[3]}</p>
              <img src={honestidad} alt="Valores" class="img-div-dis" width='' height='' />
            </div>
            <div className="div-valores">
              <p>{value[4]}</p>
              <img src={equipo} alt="Valores" class="img-div-dis" width='' height='' />
            </div>
          </div>

        </div>


        <div style={{ marginTop: "5%" }}>
          <center><h2 style={{ marginBottom: "5%" }}>Nuestra galería</h2></center>
          <Carousel infiniteLoop useKeyboardArrows autoPlay>
            <div >
              <img src={carr1} class='img-carrusel' alt='Fabrica de Materiales Preconsa' style={{ width: '50%', height: 'auto' }} />
            </div>
            <div>
              <img src={carr2} class='img-carrusel' alt='Fabrica de Materiales Preconsa' style={{ width: '50%', height: 'auto' }} />
            </div>
            <div>
              <img src={carr3} class='img-carrusel' alt='Fabrica de Materiales Preconsa' style={{ width: '50%', height: 'auto' }} />
            </div>
            <div>
              <img src={carr4} class='img-carrusel' alt='Fabrica de Materiales Preconsa' style={{ width: '50%', height: 'auto' }} />
            </div>
            <div>
              <img src={carr5} class='img-carrusel' alt='Fabrica de Materiales Preconsa' style={{ width: '50%', height: 'auto' }} />
            </div>
            <div>
              <img src={carr6} class='img-carrusel' alt='Fabrica de Materiales Preconsa' style={{ width: '50%', height: 'auto' }} />
            </div>
          </Carousel>
        </div>
      </div>


    )
  }
}

