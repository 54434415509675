import React, { Component } from 'react'
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";


import rosa from '../../../assets/cp/BMRCP.webp'
import cafe from '../../../assets/cp/BMCAFOCP.webp'
import negro from '../../../assets/cp/BMNEGCP.webp'
import amarillo from '../../../assets/cp/BMACP.webp'
import natural from '../../../assets/cp/BMNCP.webp'
import ladrillo from '../../../assets/cp/BMLADCP.webp'

import carr1 from '../../../assets/src/const_01.webp'
import carr2 from '../../../assets/src/block_07.webp'
import carr3 from '../../../assets/src/const_06.webp'

import descarga from '../../../assets/fichasTecnicas/BlockCaradePiedra.pdf'

import app from '../../../configs/firebase-config'
var itm = {}
export class Cp extends Component {

  constructor(props) {
    super(props)

    this.state = ({
      item: {},
      img: ''
    })
  }

  componentDidMount() {
    app.child('productos/BMNCP/').on('value', (snapshot) => {
      let items = {}
      items = snapshot.val()
      itm = items
      this.setState({ item: items })
    })
  }

  render() {
    return (
      <>
      
        <section class="section-marpro">
          <center><h1 class="h1p">{itm.nombre}</h1></center>
          <div class="container-fluid">
            <div class="cart">
              <div class="row">
              <div style={{width:'60%'}}>
                  <Carousel infiniteLoop autoPlay centerMode>
                    <div >
                      <img src={natural} alt="Block CP natural" width='' height='' style={{width:'70%', height:'auto'}}/>
                    </div>
                    <div>
                      <img src={amarillo}  alt="Block CP amarillo" width='' height='' style={{width:'70%', height:'auto'}}/>
                    </div>
                    <div>
                      <img src={cafe} alt="Block CP cafe" width='' height='' style={{width:'70%', height:'auto'}}/>
                    </div>
                    <div>
                      <img src={rosa}  alt="Block CP rosa" width='' height='' style={{width:'70%', height:'auto'}}/>
                    </div>
                    <div>
                      <img src={negro}  alt="Block CP negro" width='' height='' style={{width:'70%', height:'auto'}}/>
                    </div>
                    <div>
                      <img src={ladrillo} alt="Block CP ladrillo" width='' height='' style={{width:'70%', height:'auto'}}/>
                    </div>
                  </Carousel>
                </div>
                <div >
                  <div class="product-inf">
                    <div class="tabs-content">
                      <div >
                        <h2>Descripción</h2>
                        <p>{itm.descripcion}.</p>
                      </div>
                      <div >
                        <h2>Detalles</h2>
                        <p>&#x2022; Peso: {itm.peso}.</p>
                        <p>&#x2022; Dimensiones: {itm.dimensiones}.</p>
                        <p>&#x2022; Resistencia: {itm.resistencia}.</p>
                        <p>&#x2022; Rendimiento: {itm.rendimiento}.</p>
                      </div>
                    </div>
                  </div>
                    <div style={{marginTop:'5%'}}>
                      <center><h3>Colores disponibles</h3></center>
                      <div class="colors">
                        <ul>
                        <li class="li-prod natural"></li>
                          <li class="li-prod pinky" ></li>
                          <li class="li-prod black"></li>
                          <li class="li-prod brown"></li>
                          <li class="li-prod yellow"></li>
                          <li class="li-prod ladrillo"></li>
                        </ul>
                      </div>
                    </div>
                  <div>
                    <a href={descarga} download="Block Cara de Piedra" class="custom-btn">Descargar Ficha Técnica</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{marginTop:'5%', marginBottom:'5%'}}>
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false}> 
                        <div >
                            <img src={carr1} style={{width:'50%'}}/>
                        </div>
                        <div>
                            <img src={carr2} style={{width:'50%'}} />
                        </div>
                        <div>
                            <img src={carr3}  style={{width:'50%'}}/>
                        </div>
                    </Carousel>
          </section>
      </>

    )
  }
}