import React, { Component } from 'react'

import banca from '../../../assets/tuberias/banca.webp'
import maceta from '../../../assets/tuberias/jardinera.webp'
import app from '../../../configs/firebase-config'


var itm = []
var name = []
var descripcion_corta = []

export class Decorativos extends Component {
  constructor(props) {
    super(props)

    this.state = ({
      items: [],
      name: [],
      descripcion_corta: []
    })
  }

  componentDidMount() {
    app.child('productos').on('value', (snapshot) => {

      let items = []
      snapshot.forEach((child) => {
        items.push({
          nombre: child.val().nombre,
          descripcion_corta: child.val().descripcion_corta,
        })
      })
      itm = items


      name = itm.map(function (obj) {
        var robj
        robj = obj.nombre
        return robj
      })

      descripcion_corta = itm.map(function (obj) {
        var robj
        robj = obj.descripcion_corta
        return robj
      })

      this.setState({ items: items })

    })
  }

  render() {

    return (
      <div>
        <section>
          <div>
            <center><h1 class="productos-h1" >Productos decorativos</h1></center>
            <div class="principal">
              <div class="card-producto">
                <div class="title">{name[9]}</div>
                <div class="features">
                  <img src={maceta} alt="Maceta" width='' height='' className='card_logo-img' style={{ width: '50%' }}></img>
                  <p>{descripcion_corta[9]}.</p>
                </div>
                <a href="/productos/decorativo/maceta" class="btn">VER</a>
              </div>
              <div class="card-producto">
                <div class="title">{name[3]}</div>
                <div class="features">
                  <img src={banca} alt="Banca" width='' height='' className='card_logo-img' style={{ width: '80%' }}></img>
                  <p>{descripcion_corta[3]}</p>
                </div>
                <a href="/productos/decorativo/banca" class="btn">VER</a>
              </div>

            </div>
          </div>
        </section>
      </div>
    )
  }
}