import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";


import banca from '../../../assets/tuberias/banca.webp'

import carr1 from '../../../assets/src/extra_00.webp'
import carr2 from '../../../assets/src/extra_03.webp'
import carr3 from '../../../assets/src/extra_04.webp'
import descarga from '../../../assets/cr3.jpeg'

import app from '../../../configs/firebase-config'

var itm = {}
export class Banca extends Component {

  constructor(props) {
    super(props)

    this.state = ({
      item: {},
      img: ''
    })
  }

  componentDidMount() {
    app.child('productos/BANCA/').on('value', (snapshot) => {
      let items = {}
      items = snapshot.val()
      itm = items
      this.setState({ item: items })
    })
  }
  render() {
    return (
      <>
       

        <section class="section-marpro" >
          <center><h1 class="h1p">{itm.nombre}</h1></center>
          <div class="container-fluid">
            <div class="cart">
              <div class="row">
              <div style={{width:'60%'}}>
                  <img src={banca} class="img-style" width='' height='' alt='Banca' style={{width:'90%'}} />
                </div>

                <div style={{width:'45%'}}>
                  <div class="product-inf">
                    <div class="tabs-content">
                      <div >
                        <h2>Descripción</h2>
                        <p>{itm.descripcion}.</p>                      </div>
                      <div >
                        <h2>Detalles</h2>
                        <p>&#x2022; Peso: {itm.peso}.</p>
                        <p>&#x2022; Piezas: {itm.piezas}.</p>
                        <p>&#x2022; Dimensiones: {itm.dimensiones}.</p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '5%' }}>
                    <center><h3>Colores sobre pedido</h3></center>
                    <div class="colors">
                      <ul>
                        <li class="li-prod natural"></li>
                        <li class="li-prod pinky" ></li>
                        <li class="li-prod black"></li>
                        <li class="li-prod brown"></li>
                        <li class="li-prod yellow"></li>
                        <li class="li-prod ladrillo"></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{marginTop:'5%', marginBottom:'5%'}}>
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false}> 
                        <div >
                            <img src={carr1} style={{width:'50%'}}/>
                        </div>
                        <div>
                            <img src={carr2} style={{width:'50%'}} />
                        </div>
                        <div>
                            <img src={carr3}  style={{width:'50%'}}/>
                        </div>
                    </Carousel>
          </section>

      </>

    )
  }
}