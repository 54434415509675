import React, { Component } from 'react'

import app from '../../configs/firebase-config'
import cr from '../../assets/cr3.jpeg'

import { Link } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { yellow } from '@mui/material/colors'

var itm = []
var id = ''
export class Noticias extends Component {

  constructor(props) {
    super(props)

    this.state = ({
      items: [],
      titulo: [],
      descripcion: []
    })
  }


  componentDidMount() {
    app.child('blog').on('value', (snapshot) => {
      let items = []
      snapshot.forEach((child) => {
        items.push({
          key: child.key,
          titulo: child.val().titulo,
          descripcion: child.val().tipo
        })
      })

      itm = items

      this.setState({ items: items })
    })
  }


  render() {

    const ColorButton = styled(Button)(({ theme }) => ({
      marginTop: 20,
      color: theme.palette.getContrastText(yellow[700]),
      fontWeight: 1000,
      backgroundColor: yellow[700],
      '&:hover': {
        backgroundColor: yellow['A100']
      }
    }))

    return (
      <>
        <div style={{ marginTop: '5%' }} >
          <center><h1>Noticias</h1></center>
          <div className="div-blog">{
            itm.map((item) => {
              return (
                <div className="card-blog" key={item.key} value={item.value}>
                  <div style={{ fontSize: '18px', fontWeight: '400', marginBottom: '5px' }} key={item.value} value={item.value} >{item.descripcion}</div>
                  <img src={cr} className="img-blog-inicio" alt="Noticia" width='' height=''/>
                  <div style={{ fontSize: '15px', fontWeight: '200', marginTop: '5px' }} key={item.value} value={item.value}>{item.titulo}</div>
                  <ColorButton href={`/noticia/${item.key}`}>Leer más</ColorButton>
                </div>
              )
            })}
          </div>
        </div>

      </>
    )
  }

}
