import logo from '../../../assets/logo2.webp'

import React, { Component } from 'react'

export class Navbar extends Component {
  render() {
    return (
      <section className="top-nav">
        <div className="div-nav">
          <a href="/inicio"><img src={logo} className="logo-img" alt='Logo de Materiales Preconsa' width='' height='' /></a>
        </div>
        <input id="menu-toggle" type="checkbox" />
        <label className='menu-button-container' htmlFor="menu-toggle">
          <div className='menu-button'></div>
        </label>
        <ul className="menu">
          <li><a href='/inicio'>Inicio</a></li>
          <li><a href='/us'>Nosotros</a></li>
          <li><a href='/productos'>Productos</a></li>
          {/*<li><a href='/noticias'>Noticias</a></li>*/}
          <li><a href='/contacto'>Contáctanos</a></li>
        </ul>
      </section>
    );

  }
}

