import React, { Component } from 'react'
import Logo from '../../assets/logo2.webp'

const count = new Date('jun 10,2022 00:00:00').getTime()
var days = 0
var hours = 0
var minutes = 0
var seconds = 0
/*const x = setInterval(function () {
    var now = new Date().getTime()
    var d = count - now
    days = Math.floor(d / (1000 * 60 * 60 * 24))
    hours = Math.floor((d % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    minutes = Math.floor((d % (1000 * 60 * 60)) / (1000 * 60))
    seconds = Math.floor((d % (1000 * 60)) / 1000)
    document.getElementById('days').innerHTML = days
    document.getElementById('hours').innerHTML = hours
    document.getElementById('mins').innerHTML = minutes
    document.getElementById('secs').innerHTML = seconds
})*/

export class Working extends Component {
    render() {
        return (
            <div>
                <div className="container ct ac">
                <img alt='logo' src={Logo} width='' height=''/>
                    <h2>Proximamente</h2>
                    
                    <div className="count">
                        <div className="countd">
                            <span id="days"></span>
                            días
                        </div>
                        <div className="countd">
                            <span id="hours"></span>
                            horas
                        </div>
                        <div className="countd">
                            <span id="mins"></span>
                            minutos
                        </div>
                        <div className="countd">
                            <span id="secs"></span>
                            segundos
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}